import React, { Component} from 'react'
import {
	Container,
	Header,
	Image,
	Pagination,
} from 'semantic-ui-react'
import {
	Link,
	navigate,
	graphql
} from 'gatsby'
import Seo from '../components/SEO'
import Layout from '../components/Layout'
import * as styles from './styles/pages.module.css'

import {
	mobileMaxWidth,
	resizeTimer,
	tabletMaxWidth
} from '../globalVar'


export default class Recipes extends Component {
	state = {
		currentPage:1,
		limit:6,
		paginationRange:null,
		timeOut:null
	}

	componentDidMount() {
		this.handleParameters();
		this.handler();

		window.addEventListener('resize', this.resizeTimerFunc);
	}

	resizeTimerFunc = () => {
		clearTimeout(this.state.timeOut1);
		const temp = setTimeout(this.handler, resizeTimer);
		this.setState({
			timeOut1:temp
		})
	}

	componentWillUnmount() {
		clearTimeout(this.state.timeOut1);
		window.removeEventListener('resize', this.resizeTimerFunc);
	}


	handler = () => {
		//computer
		if (window.innerWidth > tabletMaxWidth ) {
			this.setState({
				paginationRange:4,
			})
		} //tablet
		else if (window.innerWidth > mobileMaxWidth ) {
			this.setState({
				paginationRange:2,
			})
		} else {  //mobile
			this.setState({
				paginationRange:1,
			})
		}
	}


	handleParameters = () => {
		const {location} = this.props
		if(location.search) {
			const parameters = location.search.split("&")
			//important checks if current result is already matched,
			//without this if statement, there'll be an infinite loop
			//
			for( let i =0; i < parameters.length; i++) {
				if(parameters[i].split("=")[0] === "page" || parameters[i].split("=")[0] === "?page") {
					this.setState({ currentPage:parameters[i].split("=")[1] })
				}else {
					//unplanned parameters
				}
			}

		}
	}

	mapBanner = (tag) =>{
		return (
			<React.Fragment key={tag.url}>
				{tag.mainImageHref && (
					<Image alt={tag.name} className={styles.categoryBanner} as={Link} to={`/${tag.url}/`} fluid src={tag.mainImageHref}/>
				)}

			</React.Fragment>
		)
	}



	render() {
		//const data = this.props.data.allMarkdownRemark.edges
		const data = this.props.data.negosyoNow.listRecipeTags
		const {
			mapBanner
		} = this

		const {
			currentPage,
			limit,
			paginationRange
		} = this.state
		let slicedRecipePage
		let length = 0
		let totalPages;
		slicedRecipePage = data

		length = slicedRecipePage.length
		totalPages=Math.ceil(length/limit)
		slicedRecipePage = slicedRecipePage.slice( (currentPage-1)*limit, limit*currentPage)


		const convertedSlicedPages = slicedRecipePage.map((item)=>{
			return {
				squareImageHref:item.squareImageHref,
				name:item.name,
				mainImageHref:item.mainImageHref,
				url:`${item.url}`
			}

			/*
			return {
				squareImageHref:item.node.frontmatter.thumbnail,//item.squareImageHref,
				name:item.node.frontmatter.title,//item.name,
				date:item.node.frontmatter.date,//undefined,
				summary:item.node.frontmatter.excerpt,//undefined,
				url:`recipes/${item.node.frontmatter.slug}`//`${item.url}`,
				//mainImageHref:item.mainImageHref
			}
			*/
		})
		const location = this.props.location
		return (
			<Layout location={location} backButton={true}>
				<Seo
					image={"https://cdn.ngnw.ph/images/category/c9581ac3/d88bcd08/banner/recipe%20and%20video.jpg"} imageWidth={1200} imageHeight={480}
					canonicalpath="/recipes"
					title={"Recipes and Videos"}
					description="Add these recipes to your menu and keep your customers craving for your specials. See how it's done, learn from our videos."
				/>
				<Container className={styles.container}>
					<Header className={styles.superHeader} >
						<h1>Recipes and Videos</h1>
					</Header>
					{/*
					<BlogList
						items={convertedSlicedPages}
						computerShown={3}
						tabletShown={2}
						mobileShown={1}
						initialShown={2}
					/>
					<div className={styles.desktopOnly}>
						<BlogList
							items={convertedSlicedPages}
							computerShown={4}
							tabletShown={4}
							mobileShown={2}
							initialShown={2}
							cardContentClassName={styles.popularCategory}
							textClassName={styles.categoryColorText}
						/>
					</div>
					*/}
					<div>
						{convertedSlicedPages.map(mapBanner)}
					</div>
					{totalPages > 1 && (
						<React.Fragment>
							<br/>
							<Pagination
								activePage={currentPage}
								onPageChange={(e, {activePage})=>{
									this.setState({
										currentPage:activePage
									})
									navigate(`${location.pathname}?page=${activePage}`)
								}}
								ellipsisItem={null}
								boundaryRange={null}
								siblingRange={paginationRange}
								totalPages={totalPages}
								pageItem={{
									className:styles.paginationItem
								}}
								firstItem={{
									className:styles.paginationItem,
									content:"<<"
								}}
								lastItem={{
									className:styles.paginationItem,
									content:">>"
								}}
								nextItem={{
									className:styles.paginationItem,
									content:">"
								}}
								prevItem={{
									className:styles.paginationItem,
									content:"<"
								}}
							/>
						</React.Fragment>
					)}
				</Container>
			</Layout>
		)
	}
}

export const data = graphql`
	query RecipeQuery {
		negosyoNow {
			listRecipeTags {
				name
				url
				squareImageHref
				mainImageHref
			}
		}
	}
`
/*


export const data = graphql`
	query RecipeQuery {
		allMarkdownRemark(
				filter: { frontmatter: { tags: { in: ["recipes"] } } }
				sort: {order:[ASC, DESC], fields:[frontmatter___order, frontmatter___date]}
			) {
			edges {
				node {
					frontmatter {
						slug
						title
						excerpt
						thumbnail
						date(formatString: "MMMM DD, YYYY")
					}
				}
			}
		}
	}
`
*/